
const MAIN_NET = true;
const CONTRACT_ADDRESS = MAIN_NET ? "0x0c07150e08e5deCfDE148E7dA5A667043F579AFC" : "0xd8f325507f614b787629d3c64ca65334c69c3fb4";
const CLAIM_MAX = 7777;
const REFRESH_SUPPLY_INTERVAL = 2000;

export {
    MAIN_NET,
    CONTRACT_ADDRESS,
    CLAIM_MAX,
    REFRESH_SUPPLY_INTERVAL
}