import './App.css';
import Home from './pages/Home/Home';
import Avatar from './pages/Avatar/Avatar';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Profile } from './pages/Profile/Profile';

function App() {

  return (
    <div>
      <Router>
        <Switch>
          <Route path="/avatar">
            <Avatar />
          </Route>
          <Route path="/profile/:id">
            <Profile />
          </Route>
          <Route path="/">
            <Home />
            </Route>
        </Switch>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
