import './Header.css';
import { Link } from "react-router-dom";

export default function Header() {
    return (
        <div className="Header">
            <div className="MediaItem">MUDVERSE</div>
            <div className="SocialMedia">
                <div className="MediaItem"><a href="https://twitter.com/mudverse" target="_blank" rel="noreferrer">Twitter</a></div>
                <div className="MediaItem"><a href="https://discord.gg/kyvN98DxNb" target="_blank" rel="noreferrer">Discord</a></div>
                <div className="MediaItem"><a href="https://mudverse.gitbook.io/mudverse" target="_blank" rel="noreferrer">GitBook</a></div>
                <div className="MediaItem"><a href="https://opensea.io/collection/mudverse-player" target="_blank" rel="noreferrer">OpenSea</a></div>
                <div className="MediaItem">
                    <Link to="/avatar" className="Avatar">Avatar</Link>
                </div>
            </div>
        </div>
    );
}