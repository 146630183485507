import { useState } from 'react';
import { utils } from 'ethers';
import { saveAs } from 'file-saver';
import './Avatar.css';
import { checkMainnet } from '../../utils/util';
import { queryNFTName } from '../../utils/eth_util';

import { CONTRACT_ADDRESS } from '../../constants';

import { generateSVG } from '../../utils/avatar/image';

const baseUrl = "https://avatar.tobi.sh/";
const mudverseBaseUrl = baseUrl + '/mudverse?size=200';

const avatarSize = 200;

export default function Avatar() {

    const [id, setId] = useState('')
    const [name, setName] = useState('')

    function idChange(e) {
        setId(e.target.value)
        setName('')
    }

    function resetAvatarUrl() {
        setId('')
        setName('')
    }

    async function generateAvatar() {
        if (!checkMainnet()) return;

        try {
            if (!id || !parseInt(id)) {
                resetAvatarUrl();
                return;
            }
        } catch (e) {
            resetAvatarUrl();
            console.error(e);
            return;
        }
        // if (id < 1 || id > 8000) return;

        const nftName = await queryNFTName(id);
        setName(nftName);
    }

    function getPixelRatio(context) {
        var backingStore = context.backingStorePixelRatio ||
          context.webkitBackingStorePixelRatio ||
          context.mozBackingStorePixelRatio ||
          context.msBackingStorePixelRatio ||
          context.oBackingStorePixelRatio ||
          context.backingStorePixelRatio || 1;
         return (window.devicePixelRatio || 1) / backingStore;
    };

    function convertImgToPNG() {
        let target = document.getElementById('imgAvatar')
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let ratio = getPixelRatio(ctx);
        let width = avatarSize * ratio;
        canvas.width = width;
        canvas.height = width;
        ctx.drawImage(target, 0, 0, width, width);

        canvas.toBlob(function(blob) {
            saveAs(blob, `${name}.png`);
        });
    }

    const SVGAvatar = () => {
        let svg = encodeURIComponent(generateSVG(id, name, avatarSize.toString(), avatarSize.toString()));
        return <img id="imgAvatar" src={`data:image/svg+xml,${svg}`} />
    };

    return (
        <div className="AvatarContent">
            <div className="ReturnHome">
                <a href="/">MUDVERSE</a>
            </div>
            <div className="AvatarMain">
                <input className="AvatarInput"
                    type="number"
                    max="8000"
                    min="1"
                    value={id}
                    onChange={idChange} 
                    placeholder="Input your NFT ID" />
                <p></p>
                <button className="AvatarButton" onClick={generateAvatar}>Generate Avatar</button>
                <p></p>
                <SVGAvatar />
                {/* <img className="AvatarImage" src={avatarPNG} fileName={"hello"} /> */}
                <p></p>
                <button className="AvatarButton" onClick={convertImgToPNG}>Download</button>
            </div>
        </div>
    );
}