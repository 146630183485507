import { toast } from 'react-toastify';

export function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function checkEthereum() {
    if (!window.ethereum) {
        toast.warn("Please Install MetaMask!");
        return false;
    }

    return true;
}

export function checkMainnet() {
    if (!checkEthereum()) return;

    if (parseInt(window.ethereum.chainId) !== 1) {
        toast.warn("Please connnect to Ethereum Mainnet!");
        return false;
    }

    return true;
}

