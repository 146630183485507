import { CONTRACT_ADDRESS } from '../constants';
import { Mudverse } from './mudverse';

export const mudverse = new Mudverse(window.ethereum, CONTRACT_ADDRESS);

export async function queryTraits(tokenId) {
    let mudverse = new Mudverse(window.ethereum, CONTRACT_ADDRESS);
    let traits = await mudverse.traitsOf(tokenId);
    return traits;
}

export async function queryNFTName(tokenId) {
    let mudverse = new Mudverse(window.ethereum, CONTRACT_ADDRESS);
    let name = await mudverse.getName(tokenId);
    return name;
}

export async function totalSupply() {
    let mudverse = new Mudverse(window.ethereum, CONTRACT_ADDRESS);
    let name = await mudverse.totalSupply();
    return name;
}