import './Cardbox.css';

export default function Cardbox() {
    const FOR_ADVENTURERS = "For Adventurers"

    return (
        <div className="BoxList">
            <a href="https://medium.com/@mudverse/mudverse-1st-round-player-for-adventurers-e7a46842fcc0" target="_blank" rel="noreferrer">
            <div id="card" className="Box Selected">
                <div className="BoxTitle">
                    <div className="Title">Player</div>
                    <div className="Subtitle">{FOR_ADVENTURERS}</div>
                </div>
                <div className="Desc">Free to claim max 7777 </div>
            </div>
            </a>

            <div className="Box">
                <div className="BoxTitle">
                    <div className="Title">Skill</div>
                    <div className="Subtitle">{FOR_ADVENTURERS}</div>
                </div>
                <div className="Desc">TBD</div>
            </div>

            <div className="Box">
                <div className="BoxTitle">
                    <div className="Title">Ability</div>
                    <div className="Subtitle">{FOR_ADVENTURERS}</div>
                </div>
                <div className="Desc">TBD</div>
            </div>

            <div className="Box">
                <div className="BoxTitle">
                    <div className="Title">NPC</div>
                    <div className="Subtitle">{FOR_ADVENTURERS}</div>
                </div>
                <div className="Desc">TBD</div>
            </div>

            <div className="Box">
                <div className="BoxTitle">
                    <div className="Title">Boss</div>
                    <div className="Subtitle">{FOR_ADVENTURERS}</div>
                </div>
                <div className="Desc">TBD</div>
            </div>

            <div className="Box">
                <div className="BoxTitle">
                    <div className="Title">City</div>
                    <div className="Subtitle">{FOR_ADVENTURERS}</div>
                </div>
                <div className="Desc">TBD</div>
            </div>
        </div>
    );
}