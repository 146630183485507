import './Home.css';
import Button from '../../components/Connector/Button';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useEffect, useRef, useState } from 'react';
import { utils } from 'ethers';
import Cardbox from '../../components/Cardbox/Cardbox';
import { randomName } from '../../name';
import { 
    MAIN_NET, 
    CONTRACT_ADDRESS, 
    CLAIM_MAX, 
    REFRESH_SUPPLY_INTERVAL 
} from '../../constants';

import { delay, checkEthereum } from '../../utils/util';
import { mudverse } from '../../utils/eth_util';

function claim(name) {
    let ABI = ["function claim(string calldata name)"];
    let iface = new utils.Interface(ABI);
    let data = iface.encodeFunctionData("claim", [name])
    return data;
}

function prepareEthMainNet() {
    let ethereum = window.ethereum
    if (MAIN_NET && parseInt(ethereum.chainId) !== 1) {
        ethereum.request({ method: "wallet_switchEthereumChain", params: [{ chainId: '0x1' }]})
            .catch(console.log)
    }
}

export default function Home() {
    const [account, setAccount] = useState('');
    const [isMinting, setIsMinting] = useState(false)
    const [remainCount, setRemainCount] = useState('---')

    useEffect(()=> {
        delay(200).then(() => {
            if (window.ethereum && window.ethereum.selectedAddress) {
                setAccount(window.ethereum.selectedAddress);
            }
        });
    })

    useEffect(() => {
        if (!window.ethereum) return;
        let count = parseInt(remainCount);
        if (count && count <= 0) return;

        async function queryTotoalSupply() {
            try {
                const supply = await mudverse.totalSupply();
                const remain = CLAIM_MAX - parseInt(supply)
                if (remain >= 0) {
                    setRemainCount(remain);
                }
            } catch (e) {
                console.log(e)
            }
        }

        queryTotoalSupply();
        const interval = setInterval(queryTotoalSupply, REFRESH_SUPPLY_INTERVAL);
        return () => clearInterval(interval);
    }, [remainCount]);

    window.ethereum?.on('accountsChanged', (accounts) => {
        if (accounts && accounts[0]) {
            setAccount(accounts[0])
        } else {
            setAccount('');
        }
    })

    window.ethereum?.on('chainChanged', (chainId) => window.location.reload());

    const requestAccount = async () => {
        if (!checkEthereum()) return;
        let ethereum = window.ethereum;
        
        if (MAIN_NET && parseInt(ethereum.chainId) !== 1) {
            prepareEthMainNet()
            return
        }

        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        if (accounts && accounts[0]) {
            setAccount(accounts[0])
        } else {
            setAccount('');
        }
    };

    const sendTx = async () => {
        if (!checkEthereum()) return;
        let ethereum = window.ethereum;

        if (MAIN_NET && parseInt(ethereum.chainId) !== 1) {
            prepareEthMainNet()
            return
        }

        if (remainCount <= 0) {
            return;
        }

        const transactionParameters = {
            from: account,
            to: CONTRACT_ADDRESS,
            // value: ethers.utils.parseEther("0.0").toHexString(),
            data: claim(randomName())
          };
          
          setIsMinting(true);
          try {
            const txHash = await ethereum?.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
            console.log(`tx hash: ${txHash}`);
          } catch (e) {
            console.error(e);
          } finally {
            setIsMinting(false);
          }
          
    };

    const Account = () => {
        let text = account ? (isMinting ? "Minting" : "Mint") : "Connect Wallet";
        let click = account ? (isMinting ? null : sendTx) : requestAccount;
        return <Button value={text} onClick={click} />;
    }

    const Connected = () => {
        if (account) {
            return (
                <div className="ConnectedAccount">
                    <span>Connected</span>
                    <span className="Address">{account.substr(0, 4)}...{account.substr(account.length - 6)}</span>
                </div>
            );
        }
        return null;
    }

    const RemainCount = () => {
        return (
            <div className="RemainCount">
                Player {remainCount} left!
            </div>
        );
    }


    return (
        <div className="Main">
            <Header />
            <div className="Content">
                <h1>MUDVERSE FOR ADVENTURERS</h1>
                <p>
                    MUDVERSE is inspired by Loot, MUD and DND cultures. <br />
                    It is randomized adventurer gear generated and stored on chain, to build metaverse infrastructure for UGC ecosystem, including all metaverse ideology, such as Player, Skill, Ability, NPC, Boss, City, Quest, Dungeon etc.<br />
                    Feel free to use MUDVERSE in any way you want.
                </p>
                <Connected />
                <Account />
                <RemainCount />
                <Cardbox />
            </div>
            {/* <Footer /> */}
        </div>
    );
}