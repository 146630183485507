let FirstName = [
    "Luka",
    "Riiviir",
    "Bennett",
    "Sawyiir",
    "WiysLe'y",
    "Connor",
    "Ayden",
    "Le'gend",
    "Evan",
    "Matteo",
    "Jeithpiir",
    "Niicholeith",
    "Ace",
    "Bebiysh",
    "Jayce",
    "Dnhmiian",
    "Braxton",
    "Lorenzo",
    "Giiovannii",
    "Jaoon",
    "August",
    "ElLi'iot",
    "Archiir",
    "Miilo",
    "Rykiir",
    "Bryeden",
    "BentLe'y",
    "Jonathan",
    "Ali'i",
    "Enzo",
    "Nathaniiel",
    "Kaiiden",
    "Atleith",
    "Robiirt",
    "Cheithe",
    "Jonah",
    "Omar",
    "Le'onardo",
    "Xandiir",
    "CharLe's",
    "CoLe",
    "Adriiel",
    "Angel",
    "Harriioon",
    "Rhett",
    "Viincent",
    "Jude",
    "Eithhton",
    "Zachary",
    "Gael",
    "Fiinn",
    "Jax",
    "Kartiir",
    "Le'on",
    "TyLi'ir",
    "Arthur",
    "Dean",
    "Malachii",
    "Calviin",
    "Zayden",
    "Hayden",
    "George",
    "IIvan",
    "Triistan",
    "ElLi'iott",
    "Cnhmden",
    "Gaviin",
    "Aviiry",
    "Nhmarii",
    "Priince",
    "Miyssiiah",
    "Walkiir",
    "Jiysse",
    "Abel",
    "Colt",
    "Maddox",
    "Ajo",
    "Kyriie",
    "Ali'ia",
    "Zane",
    "Mohnhmmed",
    "Antoniio",
    "Lukeith",
    "Tucciir",
    "Diiego",
    "Judah",
    "Miiguel",
    "Emmanuel",
    "Beccett",
    "Dawoon",
    "Zayn",
    "Kaiiro",
    "EmiiLi'iano",
    "Jose",
    "Arlo",
    "Juan",
    "Justiin",
    "Thiiago",
    "Knox",
    "Phoeniix",
    "Malakaii",
    "Iiriic"
]

let Prefix = [
    "Ab",
    "Af",
    "Ap",
    "Abu",
    "Aït",
    "Al",
    "Ālam",
    "Ath",
    "Aust",
    "Bar",
    "Bath",
    "Ben",
    "Bert",
    "Bet",
    "Bint",
    "Da",
    "Das",
    "De",
    "Degli",
    "Del",
    "Dele",
    "Della",
    "Der",
    "Di",
    "Dos",
    "Du",
    "El",
    "Fetch",
    "Fitz",
    "Kil",
    "La",
    "Le",
    "Lille",
    "Lu",
    "Mhic",
    "Mala",
    "Myljom",
    "Na",
    "Nedre",
    "Ní",
    "Nin",
    "Norr",
    "Ny",
    "Uí",
    "Opp",
    "Öfver",
    "Öster",
    "Över",
    "Øvste",
    "Öz",
    "Pour",
    "Putera",
    "Puteri",
    "Setya",
    "Stor",
    "Söder",
    "Ter",
    "Tre",
    "Van",
    "Van",
    "De",
    "Väster",
    "Verch",
    "Vest",
    "Vesle"
]


let Suffixe = [
    "ya",
    "ach",
    "acz",
    "aei",
    "ago",
    "aitis",
    "aitė",
    "aty",
    "aj",
    "ák",
    "al",
    "án",
    "and",
    "ange",
    "ano",
    "anu",
    "appa",
    "ár",
    "ář",
    "arz",
    "au",
    "auskas",
    "awan",
    "ba",
    "çı",
    "chian",
    "cule",
    "el",
    "ella",
    "ello",
    "elle",
    "en",
    "erel",
    "ers",
    "ster",
    "ette",
    "etto",
    "etti",
    "ie",
    "il",
    "illa",
    "illo",
    "illus",
    "ine",
    "ina",
    "ino",
    "ini",
    "ing",
    "ish",
    "ita",
    "ito",
    "kin",
    "le",
    "let",
    "lette",
    "ling",
    "ock",
    "ola",
    "ole",
    "olus",
    "otte",
    "rel",
    "ulum",
    "ule"
]


let FmlyFirstName = [
    "Zayden",
    "Gael",
    "Nhmarii",
    "Kaiiden",
    "Jeithpiir",
    "Le'onardo",
    "Kyriie",
    "Miilo",
    "Braxton",
    "Ali'i",
    "Le'on",
    "ElLi'iott",
    "Dean",
    "Jude",
    "Dean",
    "Enzo",
    "CharLe's",
    "George",
    "Zane",
    "Le'onardo",
    "Matteo",
    "Niicholeith",
    "Sawyiir",
    "Ace",
    "Jude",
    "TyLi'ir",
    "Atleith",
    "George",
    "Fiinn",
    "Le'on",
    "Zayden",
    "Gaviin",
    "Colt",
    "Le'on",
    "Aviiry",
    "Le'onardo",
    "Le'on",
    "Lorenzo",
    "Jude",
    "Le'gend",
    "TyLi'ir",
    "ElLi'iott",
    "Omar",
    "Gael",
    "Calviin",
    "Arthur",
    "Jax",
    "Niicholeith",
    "TyLi'ir",
    "Hayden",
    "Ajo",
    "Maddox",
    "Zayden",
    "Dean",
    "Nhmarii",
    "Nathaniiel",
    "Jeithpiir",
    "Evan",
    "Bebiysh",
    "Viincent",
    "Sawyiir",
    "Kartiir",
    "Viincent",
    "Le'on",
    "Robiirt",
    "Maddox",
    "Gael",
    "Angel",
    "Jax",
    "Malachii",
    "Abel",
    "Ajo",
    "Ali'ia",
    "Jeithpiir",
    "Lorenzo",
    "Jaoon",
    "Triistan",
    "Kartiir",
    "Jiysse",
    "Robiirt",
    "Walkiir",
    "Jax",
    "Jayce",
    "Adriiel",
    "IIvan",
    "Maddox",
    "Arthur",
    "Angel",
    "Sawyiir",
    "Kaiiden",
    "Atleith",
    "Gaviin",
    "Colt",
    "Abel",
    "Zane",
    "Omar",
    "TyLi'ir",
    "Archiir",
    "Jonathan",
    "Jonah",
    "Maddox",
    "Bryeden",
    "Priince",
    "Zane",
    "Nathaniiel",
    "Mohnhmmed",
    "Jonah",
    "Giiovannii",
    "Jonathan",
    "Dean",
    "Jayce",
    "Zayden",
    "Ayden",
    "CharLe's",
    "Bebiysh",
    "Arthur",
    "Bryeden",
    "CoLe",
    "Archiir",
    "August",
    "Kaiiden",
    "Zachary",
    "Viincent",
    "Ali'i",
    "Walkiir",
    "Kaiiden",
    "Kartiir",
    "Matteo",
    "Malachii",
    "Bebiysh",
    "Le'onardo",
    "Le'gend",
    "Jonathan",
    "Fiinn",
    "Braxton",
    "BentLe'y",
    "Ayden",
    "Dnhmiian",
    "CharLe's",
    "Ace",
    "Braxton",
    "Evan",
    "Calviin",
    "Rykiir",
    "Enzo",
    "Mohnhmmed",
    "Atleith",
    "George",
    "Le'on",
    "Xandiir",
    "Jiysse",
    "Maddox",
    "Jayce",
    "Calviin",
    "Abel",
    "Fiinn",
    "Dean",
    "Eithhton",
    "Mohnhmmed",
    "Kyriie",
    "Jax",
    "WiysLe'y",
    "Ali'ia",
    "Jonathan",
    "George",
    "Jiysse",
    "Jonathan",
    "Gaviin",
    "Priince",
    "Nhmarii",
    "Lorenzo",
    "Nathaniiel",
    "Enzo",
    "Omar",
    "Rhett",
    "Jonah",
    "Abel",
    "Eithhton",
    "Rhett",
    "Niicholeith",
    "Kartiir",
    "Miilo",
    "Kyriie",
    "Jonah",
    "Zachary",
    "Bryeden",
    "Ayden",
    "Jiysse",
    "Calviin",
    "Jeithpiir",
    "Kaiiden",
    "Jiysse",
    "Rykiir",
    "Ali'ia",
    "Jayce",
    "Maddox",
    "Ali'i",
    "BentLe'y",
    "Miyssiiah",
    "Giiovannii",
    "BentLe'y",
    "Walkiir",
    "Hayden",
    "BentLe'y",
    "Giiovannii",
    "Kaiiden",
    "Ace",
    "Priince",
    "ElLi'iot",
    "Zayden",
    "Viincent",
    "CharLe's",
    "Miyssiiah",
    "Jayce",
    "Triistan",
    "Fiinn",
    "Bebiysh",
    "Triistan",
    "Jude",
    "Fiinn",
    "Robiirt",
    "Zachary",
    "Jude",
    "Cnhmden",
    "Le'gend",
    "TyLi'ir",
    "Miyssiiah",
    "Zane",
    "Kartiir",
    "Kartiir",
    "Jeithpiir",
    "Ayden",
    "Zachary",
    "Connor",
    "Kartiir",
    "Kaiiden",
    "Bebiysh",
    "Atleith",
    "Jonah",
    "Matteo",
    "Fiinn",
    "Giiovannii",
    "Braxton",
    "Jude",
    "Bebiysh",
    "Cheithe",
    "Zayden",
    "CharLe's",
    "Le'onardo",
    "Colt",
    "Angel",
    "Nhmarii",
    "Gaviin",
    "Dnhmiian",
    "Atleith",
    "Braxton",
    "ElLi'iot",
    "Le'onardo",
    "CharLe's",
    "Cnhmden",
    "Jiysse",
    "ElLi'iott",
    "Arthur",
    "Le'on",
    "Omar",
    "Fiinn",
    "Kyriie",
    "Jonah",
    "Miilo",
    "Jiysse",
    "Mohnhmmed",
    "Calviin",
    "Jonathan",
    "Arthur",
    "Rykiir",
    "Adriiel",
    "ElLi'iot",
    "Gael",
    "Xandiir",
    "Ali'ia",
    "Calviin",
    "Colt",
    "Robiirt",
    "Giiovannii",
    "Walkiir",
    "WiysLe'y",
    "Ali'i",
    "Colt",
    "WiysLe'y",
    "Miyssiiah",
    "Zane",
    "Evan",
    "Zachary",
    "Evan",
    "Omar",
    "Nhmarii",
    "Jonathan",
    "Viincent",
    "Miyssiiah",
    "Fiinn",
    "Calviin",
    "Ajo",
    "Le'gend",
    "Zachary",
    "Le'gend",
    "Robiirt",
    "Nathaniiel",
    "Jeithpiir",
    "Le'onardo",
    "Ali'ia",
    "Nhmarii",
    "Bryeden",
    "Bebiysh",
    "Jonah",
    "Le'onardo",
    "Angel",
    "Jeithpiir",
    "Zane",
    "Enzo",
    "ElLi'iot",
    "Kartiir",
    "Omar",
    "Harriioon",
    "Xandiir",
    "Rhett",
    "Kartiir",
    "Ali'ia",
    "IIvan",
    "Arthur",
    "Le'on",
    "Kaiiden",
    "Gael",
    "Fiinn",
    "Aviiry",
    "BentLe'y",
    "Maddox",
    "Rhett",
    "CoLe",
    "Kartiir",
    "Ali'i",
    "Ajo",
    "Priince",
    "Adriiel",
    "Archiir",
    "Niicholeith",
    "Niicholeith",
    "CharLe's",
    "Rhett",
    "Viincent",
    "Miyssiiah",
    "Rhett",
    "Le'on",
    "Nhmarii",
    "August",
    "Mohnhmmed",
    "Jiysse",
    "Miilo",
    "Hayden",
    "Mohnhmmed",
    "Hayden",
    "Rykiir",
    "Fiinn",
    "Jude",
    "Kyriie",
    "Enzo",
    "TyLi'ir",
    "Mohnhmmed",
    "Harriioon",
    "Triistan",
    "Archiir",
    "Abel",
    "Jaoon",
    "Cnhmden",
    "Ajo",
    "Ace",
    "Jaoon",
    "Harriioon",
    "Kyriie",
    "Adriiel",
    "Eithhton",
    "TyLi'ir",
    "Colt",
    "Dnhmiian",
    "Gaviin",
    "Gael",
    "Fiinn",
    "Zayden",
    "IIvan",
    "Jiysse",
    "TyLi'ir",
    "Xandiir",
    "Lorenzo",
    "Evan",
    "Harriioon",
    "IIvan",
    "ElLi'iot",
    "Atleith",
    "WiysLe'y",
    "Le'on",
    "Le'onardo",
    "Gaviin",
    "Ace",
    "CharLe's",
    "Robiirt",
    "George",
    "Nathaniiel",
    "Malachii",
    "Nhmarii",
    "Nhmarii",
    "Le'on",
    "Zane",
    "Nhmarii",
    "Niicholeith",
    "Cnhmden",
    "Kyriie",
    "CoLe",
    "Dean",
    "Nhmarii",
    "Jiysse",
    "CoLe",
    "Kartiir",
    "Xandiir",
    "Jiysse",
    "Harriioon"
]



let FmlyPrefix = [
    "Öfver",
    "Myljom",
    "Ní",
    "Dos",
    "Myljom",
    "Ālam",
    "Degli",
    "Le",
    "Du",
    "Aust",
    "Ath",
    "Della",
    "Öfver",
    "Bar",
    "Der",
    "Fetch",
    "Fetch",
    "Da",
    "Bint",
    "La",
    "Aust",
    "Der",
    "Øvste",
    "Över",
    "Al",
    "Myljom",
    "Ben",
    "Öfver",
    "Nedre",
    "Le",
    "Ben",
    "Ny",
    "Mala",
    "Mala",
    "Ní",
    "De",
    "Öfver",
    "Na",
    "El",
    "Degli",
    "El",
    "Ālam",
    "Bar",
    "Fetch",
    "Ní",
    "Dele",
    "Al",
    "Aust",
    "Lu",
    "Uí",
    "Bert",
    "Na",
    "Fitz",
    "Lille",
    "El",
    "Uí",
    "Ní",
    "Dos",
    "El",
    "Bar",
    "Das",
    "Dos",
    "Lu",
    "Aït",
    "Dele",
    "De",
    "Mhic",
    "Öz",
    "Na",
    "Al",
    "Ny",
    "Ní",
    "Öfver",
    "Aust",
    "Fitz",
    "Ní",
    "Ben",
    "El",
    "Abu",
    "Mala",
    "Öz",
    "El",
    "Da",
    "Kil",
    "Över",
    "Della",
    "Ath",
    "Aust",
    "Dele",
    "Bet",
    "Bet",
    "Bar",
    "Ath",
    "Uí",
    "Norr",
    "La",
    "Fitz",
    "Opp",
    "Nedre",
    "Degli",
    "Uí",
    "Bath",
    "Ālam",
    "Øvste",
    "Das",
    "Fitz",
    "Aït",
    "Ben",
    "Ní",
    "Ālam",
    "Dele",
    "Bert",
    "La",
    "Kil",
    "Dele",
    "Uí",
    "Kil",
    "El",
    "Du",
    "Della",
    "Myljom",
    "Mala",
    "Norr",
    "Bath",
    "Abu",
    "La",
    "Ní",
    "Della",
    "Le",
    "Al",
    "Dele",
    "Di",
    "Ben",
    "Opp",
    "Nedre",
    "Ālam",
    "Das",
    "Na",
    "Degli",
    "Na",
    "Uí",
    "Öz",
    "Mhic",
    "Opp",
    "Ní",
    "Lu",
    "Bath",
    "Del",
    "Uí",
    "Da",
    "Kil",
    "Du",
    "Bet",
    "Abu",
    "Mhic",
    "Mhic",
    "Bar",
    "Øvste",
    "Öz",
    "Myljom",
    "Aït",
    "Na",
    "De",
    "Mhic",
    "Del",
    "Lu",
    "El",
    "Öfver",
    "Öfver",
    "De",
    "Degli",
    "Öster",
    "Mala",
    "Uí",
    "Lu",
    "Del",
    "Das",
    "Lille",
    "Le",
    "Aït",
    "Ny",
    "Opp",
    "Ní",
    "Över",
    "Ben",
    "Fetch",
    "El",
    "Öz",
    "Öfver",
    "Bint",
    "Da",
    "Di",
    "Na",
    "Bert",
    "Nedre",
    "Del",
    "Nin",
    "Ben",
    "Ní",
    "Das",
    "Mhic",
    "Öz",
    "Uí",
    "Na",
    "Mhic",
    "Ben",
    "Öfver",
    "Bint",
    "Öfver",
    "Da",
    "Fetch",
    "Uí",
    "La",
    "Lu",
    "Ben",
    "Le",
    "Abu",
    "Lu",
    "Aït",
    "Nin",
    "Di",
    "De",
    "Ben",
    "Mhic",
    "Pour",
    "Della",
    "Der",
    "Ath",
    "Bet",
    "Bint",
    "Bar",
    "Mala",
    "Ny",
    "Della",
    "Fitz",
    "Lu",
    "Al",
    "Al",
    "Ní",
    "Myljom",
    "Aït",
    "Mhic",
    "Abu",
    "Bet",
    "Aït",
    "Øvste",
    "Degli",
    "Norr",
    "Bint",
    "Dele",
    "La",
    "Fetch",
    "Mala",
    "Da",
    "La",
    "Aust",
    "Fitz",
    "Al",
    "Lu",
    "Aït",
    "Degli",
    "Øvste",
    "Nin",
    "Bath",
    "Øvste",
    "Över",
    "Över",
    "Fetch",
    "Dos",
    "Da",
    "Al",
    "Myljom",
    "Över",
    "Della",
    "Na",
    "Bint",
    "Al",
    "Della",
    "Di",
    "Da",
    "Ath",
    "Över",
    "Aust",
    "Dele",
    "Du",
    "Nin",
    "De",
    "Della",
    "Fetch",
    "Öz",
    "Mhic",
    "Nedre",
    "Uí",
    "Lu",
    "Ny",
    "La",
    "Dele",
    "Myljom",
    "Bath",
    "Mala",
    "Lu",
    "Myljom",
    "Öster",
    "El",
    "Aït",
    "Ālam",
    "Över",
    "Le",
    "Abu",
    "Abu",
    "Öz",
    "Opp",
    "Abu",
    "El",
    "Na",
    "Bert",
    "Abu",
    "Dos",
    "Ath",
    "Mhic",
    "Norr",
    "De",
    "Da",
    "Mala",
    "Ālam",
    "Le",
    "Lu",
    "La",
    "Al",
    "Bar",
    "Das",
    "Del",
    "Bert",
    "Bath",
    "Dele",
    "Abu",
    "Nedre",
    "Bert",
    "Bath",
    "Ath",
    "De",
    "Aït",
    "Bet",
    "Dele",
    "Bint",
    "Fitz",
    "Da",
    "Lu",
    "Dos",
    "Degli",
    "Al",
    "Ní",
    "Bint",
    "El",
    "Bint",
    "Öfver",
    "Della",
    "Fetch",
    "Øvste",
    "Myljom",
    "Der",
    "Ben",
    "Mala",
    "Bar",
    "Norr",
    "El",
    "Fetch",
    "Öfver",
    "Der",
    "El",
    "Bar",
    "Uí",
    "Opp",
    "Na",
    "Della",
    "Nin",
    "Mhic",
    "Della",
    "Aït",
    "Uí",
    "Bath",
    "Der",
    "Na",
    "Abu",
    "Öz",
    "Lu",
    "Öfver",
    "Öz",
    "Myljom",
    "Bint",
    "Nin",
    "Kil",
    "Ālam",
    "Öfver",
    "Das",
    "Das",
    "Ben",
    "Öfver",
    "Degli",
    "Degli",
    "Öfver",
    "Le",
    "Nedre",
    "Nedre",
    "Del",
    "Della",
    "Di",
    "Aust",
    "Ní",
    "Öfver",
    "Das",
    "Ny",
    "Ben",
    "Ath",
    "Nin",
    "Ben",
    "Nedre",
    "Fitz",
    "Aït",
    "Kil",
    "Nedre",
    "Mala",
    "Bert",
    "Ní"
]



let FmlySuffix = [
    "ello",
    "ák",
    "ba",
    "illus",
    "cule",
    "aei",
    "au",
    "illo",
    "illus",
    "ers",
    "án",
    "aei",
    "çı",
    "al",
    "illo",
    "anu",
    "and",
    "chian",
    "aitė",
    "ette",
    "etti",
    "ella",
    "illo",
    "cule",
    "ers",
    "aitis",
    "ine",
    "il",
    "ago",
    "appa",
    "and",
    "ano",
    "aitė",
    "ba",
    "il",
    "ba",
    "ello",
    "ster",
    "ello",
    "al",
    "en",
    "ita",
    "chian",
    "ito",
    "aei",
    "ino",
    "illa",
    "ář",
    "ár",
    "aitis",
    "appa",
    "aitis",
    "ette",
    "ish",
    "aitė",
    "ito",
    "awan",
    "çı",
    "il",
    "illo",
    "ing",
    "ie",
    "aty",
    "aei",
    "aitė",
    "cule",
    "ák",
    "çı",
    "ish",
    "cule",
    "chian",
    "chian",
    "elle",
    "au",
    "ář",
    "au",
    "ba",
    "ie",
    "en",
    "ák",
    "ing",
    "al",
    "ella",
    "illo",
    "illa",
    "illus",
    "aj",
    "and",
    "çı",
    "chian",
    "il",
    "ster",
    "elle",
    "auskas",
    "illus",
    "and",
    "illa",
    "án",
    "ák",
    "án",
    "aei",
    "aei",
    "aty",
    "aei",
    "al",
    "ina",
    "aei",
    "ing",
    "aitė",
    "illa",
    "ette",
    "án",
    "ago",
    "ář",
    "ba",
    "ino",
    "ie",
    "illus",
    "aitis",
    "anu",
    "and",
    "ine",
    "ange",
    "ster",
    "çı",
    "etto",
    "el",
    "án",
    "ette",
    "erel",
    "el",
    "aitė",
    "cule",
    "anu",
    "ange",
    "en",
    "ster",
    "en",
    "ita",
    "awan",
    "elle",
    "ine",
    "illus",
    "aitė",
    "ito",
    "án",
    "aty",
    "ino",
    "ello",
    "ers",
    "au",
    "ba",
    "au",
    "cule",
    "ers",
    "ita",
    "aitis",
    "ers",
    "en",
    "al",
    "ago",
    "ie",
    "appa",
    "ina",
    "awan",
    "ie",
    "al",
    "anu",
    "ange",
    "ello",
    "and",
    "etto",
    "ie",
    "ár",
    "ello",
    "erel",
    "aei",
    "illus",
    "ba",
    "ita",
    "çı",
    "ago",
    "aty",
    "ini",
    "án",
    "ago",
    "án",
    "illus",
    "aitė",
    "en",
    "ster",
    "ba",
    "aei",
    "etto",
    "ba",
    "au",
    "al",
    "illa",
    "ine",
    "ák",
    "el",
    "illa",
    "ie",
    "ing",
    "illo",
    "ago",
    "án",
    "aei",
    "ish",
    "au",
    "en",
    "cule",
    "cule",
    "illa",
    "awan",
    "ini",
    "ino",
    "anu",
    "aitis",
    "ina",
    "ers",
    "ba",
    "ette",
    "illo",
    "án",
    "án",
    "awan",
    "ine",
    "ita",
    "ie",
    "aitė",
    "chian",
    "çı",
    "ster",
    "aei",
    "ár",
    "au",
    "ago",
    "aty",
    "ine",
    "ář",
    "erel",
    "ita",
    "arz",
    "and",
    "ine",
    "anu",
    "illus",
    "el",
    "ina",
    "ster",
    "ine",
    "ish",
    "illo",
    "anu",
    "aj",
    "ster",
    "erel",
    "al",
    "ár",
    "and",
    "ár",
    "ster",
    "anu",
    "etto",
    "ini",
    "ina",
    "ange",
    "anu",
    "el",
    "cule",
    "il",
    "elle",
    "illo",
    "ano",
    "ing",
    "ing",
    "ano",
    "aitė",
    "ish",
    "cule",
    "ette",
    "and",
    "ange",
    "ago",
    "illa",
    "ba",
    "ini",
    "ish",
    "illus",
    "ba",
    "illa",
    "ář",
    "ita",
    "appa",
    "aty",
    "and",
    "aitė",
    "ano",
    "ie",
    "erel",
    "al",
    "illo",
    "ster",
    "aitė",
    "chian",
    "ita",
    "ish",
    "çı",
    "ers",
    "ie",
    "ine",
    "ár",
    "ák",
    "illus",
    "ie",
    "ář",
    "etti",
    "etti",
    "ing",
    "ini",
    "anu",
    "çı",
    "awan",
    "aty",
    "ito",
    "and",
    "ba",
    "ers",
    "etti",
    "illus",
    "ář",
    "illo",
    "ster",
    "aitė",
    "ini",
    "ish",
    "etti",
    "ie",
    "ini",
    "ange",
    "ár",
    "el",
    "ine",
    "çı",
    "ange",
    "en",
    "al",
    "al",
    "ito",
    "auskas",
    "al",
    "ář",
    "chian",
    "ish",
    "ák",
    "ini",
    "ish",
    "arz",
    "ello",
    "ie",
    "ba",
    "erel",
    "án",
    "anu",
    "ago",
    "aitis",
    "çı",
    "el",
    "elle",
    "ano",
    "ange",
    "ine",
    "elle",
    "ago",
    "án",
    "ita",
    "ina",
    "ita",
    "aitis",
    "el",
    "illo",
    "aei",
    "etto",
    "cule",
    "auskas",
    "il",
    "auskas",
    "anu",
    "ster",
    "etto",
    "el",
    "ing",
    "aitis",
    "ano",
    "aitė",
    "illo",
    "arz",
    "ette",
    "ba",
    "aj",
    "ange",
    "aitė",
    "ange",
    "çı",
    "ita",
    "illa",
    "ito",
    "auskas",
    "ella",
    "al",
    "ing",
    "cule",
    "ie",
    "ster",
    "ito",
    "ange",
    "elle",
    "au",
    "ette",
    "cule",
    "ine",
    "aitė",
    "illa"
]

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function randomName() {
    if (getRandomInt(2) === 0) {
        const firstName = getRandomInt(FirstName.length);
        const prefix = getRandomInt(Prefix.length);
        const suffixe = getRandomInt(Suffixe.length);
        return `${FirstName[firstName]} ${Prefix[prefix]}${Suffixe[suffixe]}`;

    } else {
        const firstName = getRandomInt(FmlyFirstName.length);
        const prefix = getRandomInt(FmlyPrefix.length);
        const suffixe = getRandomInt(FmlySuffix.length);
        return `${FmlyFirstName[firstName]} ${FmlyPrefix[prefix]}${FmlySuffix[suffixe]}`;
    }
}