import { useEffect, useState } from 'react';
import {
    useParams
  } from "react-router-dom";
import { queryTraits, queryNFTName } from '../../utils/eth_util';
import { checkEthereum } from '../../utils/util';
import './Profile.css';

export function Profile() {
    const [name, setName] = useState('')
    const [traits, setTraits] = useState([])

    const ProfileProperty = () => {
        if (!traits || traits.length < 1) return null;

        function color(value) {
            if (!parseInt(value)) return null;

            if (value <= 5) {
                return "gray";
            } else if (value <= 15) {
                return "white";
            } else if (value <= 25) {
                return "green";
            } else if (value <= 35) {
                return "blue";
            } else {
                return "purple";
            }
        }

        return (
            <div className="TraitList">
            { traits.map((x) => (
                    <div key={x['value']} className="TraitItem">
                        <span style={{ color: color(x['value'])}} className="TraitType"> {x['trait_type']}</span>
                        <span style={{ color: color(x['value'])}} className="TraitValue"> {x['value']}</span> 
                    </div>
                ))
            }
            </div>
        );
    }

    useEffect(() => {
        queryTraits(82).then(setTraits).catch(console.log);
        queryTraits(82).then(setName).catch(console.log);
    }, []);

    return (
            <div className="ProfileContent">
                <img className="ProfileImage" src="data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%0A%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0A%3Csvg%20width%3D%22200%22%20height%3D%22200%22%20viewBox%3D%220%200%20200%20200%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cg%3E%0A%20%20%20%20%3Cdefs%3E%0A%20%20%20%20%20%20%3ClinearGradient%20id%3D%22avatar%22%20x1%3D%220%22%20y1%3D%220%22%20x2%3D%221%22%20y2%3D%221%22%3E%0A%20%20%20%20%20%20%20%20%3Cstop%20offset%3D%220%25%22%20stop-color%3D%22%237DFF45%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cstop%20offset%3D%22100%25%22%20stop-color%3D%22%23FF6371%22%2F%3E%0A%20%20%20%20%20%20%3C%2FlinearGradient%3E%0A%20%20%20%20%3C%2Fdefs%3E%0A%20%20%20%20%3Crect%20fill%3D%22url(%23avatar)%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22200%22%20height%3D%22200%22%2F%3E%0A%20%20%20%20%3Ctext%20x%3D%2250%25%22%20y%3D%2250%25%22%20alignment-baseline%3D%22central%22%20dominant-baseline%3D%22central%22%20text-anchor%3D%22middle%22%20fill%3D%22%23fff%22%20font-family%3D%22sans-serif%22%20font-size%3D%2221%22%3EReady%20Player%20One%3C%2Ftext%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A" />
                <div className="ProfileProperty">
                    <ProfileProperty />
                </div>
            </div>
    );
}